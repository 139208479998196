import React from "react";
import { CellProps } from "./Cell";
import { formatMoneyCurrency } from "../../../../utils";
import { Typography } from "@commonsku/styles";
import { BASE_COLUMN_TYPES } from "../config/types";

type TColumn = typeof BASE_COLUMN_TYPES.Margin;
type TValue = {
  total_margin_amount: string | number;
  total_margin: null | string | number;
  currency_id: string;
};
const MarginCell = ({
  value,
  className,
  onClick,
  style = {},
  textStyle = {},
}: CellProps<TColumn, TValue>) => {
  const percent = (+value.total_margin || 0) + "%";
  const amount = formatMoneyCurrency(
    value.total_margin_amount ?? "0",
    value.currency_id,
  );

  return (
    <div className={className} onClick={onClick}>
      <div style={style} title={percent}>
        <Typography.Span
          style={{
            display: "block",
            textAlign: "right",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {percent}
        </Typography.Span>
        <Typography.Span
          style={{
            display: "block",
            textAlign: "right",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {amount}
        </Typography.Span>
      </div>
    </div>
  );
};

export default MarginCell;
